<template>
  <v-form>
    <confirm ref="confirm"></confirm>
    <v-card elevation="2" outlined>
      <v-toolbar v-if="false">
        {{ prj && prj._id }}
        <v-spacer></v-spacer>

        <v-btn v-on:click="saveProject"
          >Salvar<v-icon>mdi-file-cabinet</v-icon>
        </v-btn>
        <v-btn v-on:click="exitFromProject"
          >Sair<v-icon>mdi-reply</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-tabs>
          <v-tab>
            <div class="menuLateral">Dados do Projeto</div>
          </v-tab>
          <v-tab>
            <div class="menuLateral">Locais de Apresentação</div>
          </v-tab>
          <v-tab>
            <div class="menuLateral">Relatório</div>
          </v-tab>
          <v-tab-item>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-card tile elevation="5" outlined>
                  <v-card-title>
                    <p>Dados principais</p>
                  </v-card-title>
                  <v-card-text>
                    <v-row align="start">
                      <v-col cols="12" md="6" sm="12">
                        <h-text-input
                          v-model="prj.titulo"
                          maxlength="50"
                          counter="50"
                          tip="Informe um nome para o seu projeto. O nome pode conter até 50 caracteres e será utilizado tanto para ser referenciado na sua lista de projetos quanto para ser o título do certificado."
                          placeholder="Informe o nome do projeto"
                          label="Nome do projeto"
                        />
                      </v-col>
                      <v-col cols="12" md="6" sm="12">
                        <h-text-input
                          v-model="prj.proponente"
                          maxlength="50"
                          counter="50"
                          tip="O nome do principal proponente ou da equipe responsável pela elaboração do projeto. "
                          placeholder="Informe o nome do proponente"
                          label="Nome do proponente"
                        />
                      </v-col>
                    </v-row>
                    <v-row align="end">
                      <v-col cols="12" md="6" sm="12">
                        <h-text-area
                          v-model="prj.resumo"
                          maxlength="250"
                          counter="250"
                          tip="Resuma em até 250 caracteres, os principais pontos relevantes do seu projeto."
                          placeholder="Breve resumo sobre o projeto (opcional)"
                          label="Resumo do projeto"
                        ></h-text-area>
                      </v-col>
                      <v-col cols="12" md="6" sm="12" align-content="baseline">
                        <h4>Segmento artístico</h4>
                        <v-select
                          required
                          v-model="prj.segmento"
                          :items="segmentos"
                          item-text="label"
                          item-value="id"
                          label="Segmento"
                          return-object
                          single-line
                          outlined
                          placeholder="Selecione um segmento"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-card outlined elevation="5">
                  <v-card-title>
                    <p>Fontes de Recursos Financeiros</p>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="3">
                        <v-row
                          v-for="fonte in prj.fontesRecurso"
                          v-bind:key="fonte.code"
                        >
                          <v-col class="relative">
                            <h-money-input
                              v-bind:options="{
                                precision: 0,
                                outputMask: 'Integer',
                                locale: 'pt-BR',
                              }"
                              v-bind:tip="fonte.hint"
                              v-model="fonte.value"
                              v-bind:label="
                                fontesRecurso.find((o) => o.code == fonte.code)
                                  .label
                              "
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="totalFontesRecurso">
                            <h-money-input
                              v-model="valorTotalFonteRecurso"
                              v-bind:options="{
                                precision: 0,
                                readOnly: true,
                                outputMask: 'Integer',
                                locale: 'pt-BR',
                              }"
                              v-bind:readonly="true"
                              tip="Valor total das fontes de recurso (GOP)"
                              v-bind:label="'Total'"
                            />
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="12" lg="9" md="9">
                        <v-row>
                          <v-col>
                            <v-chart
                              class="chart"
                              :option="dadosGraficoFonteRecurso"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <template v-if="prj && prj.locais && prj.locais.length > 0">
              <template v-if="prj && prj.locais">
                <v-sheet
                  flat
                  outlined
                  v-bind:key="idx"
                  v-for="(local, idx) in prj.locais"
                >
                  <table cellspacing="0" cellpadding="0" class="sheet">
                    <tr>
                      <td
                        v-bind:style="
                          'background-color:' +
                            sheetColors[idx % sheetColors.length].background
                        "
                        style="width:15px"
                      >
                        &nbsp;
                      </td>
                      <td>
                        <v-card-title>
                          <v-row>
                            <v-col
                              v-bind:style="
                                'position:relative;margin:-5px 0 20px -5px;display:block;background-color:' +
                                  sheetColors[idx % sheetColors.length]
                                    .background +
                                  ';color:' +
                                  sheetColors[idx % sheetColors.length].color
                              "
                              cols="12"
                              lg="12"
                              md="12"
                            >
                              <v-row>
                                <v-col> LOCAL {{ idx + 1 }} </v-col>
                                <v-spacer></v-spacer>
                                <v-col>
                                  <v-btn
                                    v-if="idx > 0"
                                    @click="removeLocal(idx)"
                                  >
                                    remover este local</v-btn
                                  >
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="12" md="3" sm="12">
                              <h-text-input
                                label="Nome do estabelecimento"
                                required
                                hint="Informe o nome do estabelecimento"
                                v-model="local.estabelecimento"
                              />
                            </v-col>
                            <v-col cols="12" md="3" sm="12">
                              <h-text-input
                                class=""
                                label="Cidade"
                                tip="Informe o nome da cidade onde o estabelecimento se situa"
                                v-model="local.cidade"
                              />
                            </v-col>
                            <v-col cols="12" md="2" sm="12">
                              <h-text-input
                                label="Estado"
                                tip="Informe o estado (ex: São Paulo, Rio de Janeiro, Pernambuco)"
                                v-model="local.estado"
                              />
                            </v-col>
                            <v-col cols="12" md="4" sm="12">
                              <h-money-input
                                v-bind:options="{
                                  precision: 0,
                                  length: 7,
                                  outputMask: 'Integer',
                                  locale: 'pt-BR',
                                }"
                                type="number"
                                label="Lotação (pessoas)"
                                tip="Informe a lotação máxima aproximada do estabelecimento"
                                v-bind:outlined="true"
                                v-model="local.lotacao"
                              />
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <v-card-text>
                          <template>
                            <v-tabs
                              v-model="local.dia"
                              show-arrows
                              next-icon="mdi-arrow-right-bold-box-outline"
                              prev-icon="mdi-arrow-left-bold-box-outline"
                            >
                              <v-tab
                                class="diaItem"
                                v-for="ndia in Math.max(
                                  parseInt(local.qtApresentacoes),
                                  1
                                )"
                                v-bind:key="ndia"
                              >
                                dia {{ ndia }}
                              </v-tab>
                              <v-sheet :elevation="5" dense>
                                <v-row>
                                  <template v-if="local.qtApresentacoes > 1">
                                    <v-col>
                                      <v-btn icon @click="removerDias(local)">
                                        <v-icon>mdi-minus</v-icon>
                                      </v-btn>
                                    </v-col>
                                  </template>
                                  <template v-if="local.qtApresentacoes < 8">
                                    <v-col>
                                      <v-btn icon @click="adicionarDias(local)">
                                        <v-icon>mdi-plus</v-icon>
                                      </v-btn>
                                    </v-col>
                                  </template>
                                </v-row>
                              </v-sheet>

                              <template v-if="local">
                                <v-tabs-items v-model="local.dia">
                                  <v-tab-item
                                    v-for="ndia in Math.max(
                                      parseInt(local.qtApresentacoes),
                                      1
                                    )"
                                    v-bind:key="ndia"
                                  >
                                    <v-sheet fixed class="tableContainer">
                                      <table
                                        class="dataSheet"
                                        style="width:100%"
                                      >
                                        <caption
                                          v-bind:style="
                                            'background-color:' +
                                              sheetColors[
                                                idx % sheetColors.length
                                              ].title.background +
                                              ';color:' +
                                              sheetColors[
                                                idx % sheetColors.length
                                              ].title.color
                                          "
                                          style="font-size:20px;text-transform:uppercase;background-color:#eee;font-weight:bolder;padding:20px 0px"
                                        >
                                          Dia
                                          {{
                                            local.dia + 1
                                          }}
                                        </caption>
                                        <thead>
                                          <tr valign="bottom">
                                            <th rowspan="3"></th>
                                            <th
                                              rowspan="3"
                                              style="border-left:none"
                                            >
                                              Público
                                            </th>
                                            <th
                                              colspan="7"
                                              style="border-left:none;"
                                            >
                                              Estimativa de despesa diária por
                                              pessoa
                                            </th>
                                          </tr>
                                          <tr>
                                            <th colspan="2">
                                              Despesas com Transporte R$
                                            </th>
                                            <th rowspan="2">
                                              Hospedagem
                                            </th>
                                            <th rowspan="2">
                                              Alimentos e<br />
                                              Bebidas
                                            </th>
                                            <th rowspan="2">
                                              Outras
                                            </th>
                                            <th rowspan="2" class="totalColumn">
                                              Total por pessoa
                                            </th>
                                            <th rowspan="2" class="totalColumn">
                                              Total
                                            </th>
                                          </tr>
                                          <tr>
                                            <th>Intermunicipal</th>
                                            <th>Intramunicipal</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            v-for="tipo in tipoPublico"
                                            v-bind:key="tipo.label"
                                          >
                                            <template
                                              v-if="
                                                local.despesas[local.dia] &&
                                                  local.despesas[local.dia][
                                                    tipo.key
                                                  ]
                                              "
                                            >
                                              <td
                                                style="width:130px;padding-left:10px;border:none"
                                              >
                                                <div class="tipoPublico">
                                                  {{ tipo.label }}
                                                </div>
                                              </td>
                                              <td valign="middle">
                                                <h-money-input
                                                  v-bind:properties="
                                                    formatoColunasPlanilha
                                                  "
                                                  v-bind:autocomplete="'new'"
                                                  class="right-input"
                                                  align="right"
                                                  v-bind:altlabel="
                                                    'Público estimado'
                                                  "
                                                  v-bind:tip="
                                                    'Informe o público estimado de ' +
                                                      tipo.tip
                                                  "
                                                  v-bind:options="{
                                                    precision: 0,
                                                    length: 7,
                                                    locale: 'pt-BR',
                                                  }"
                                                  v-model="
                                                    local.despesas[local.dia][
                                                      tipo.key
                                                    ].publico
                                                  "
                                                />
                                              </td>
                                              <td>
                                                <h-money-input
                                                  class="right-input"
                                                  v-bind:properties="
                                                    formatoColunasPlanilha
                                                  "
                                                  v-bind:autocomplete="'new'"
                                                  v-bind:altlabel="
                                                    'Despesa com transporte intermunicipal'
                                                  "
                                                  v-bind:tip="
                                                    'Informe o valor aproximado gasto com transporte entre cidades por ' +
                                                      tipo.tip
                                                  "
                                                  v-bind:options="{
                                                    outputMask:
                                                      '################',
                                                    length: 6,
                                                    precision: 0,
                                                    locale: 'pt-BR',
                                                  }"
                                                  v-model="
                                                    local.despesas[local.dia][
                                                      tipo.key
                                                    ].despesaTransporteChegada
                                                  "
                                                />
                                              </td>
                                              <td>
                                                <h-money-input
                                                  class="right-input"
                                                  v-bind:properties="
                                                    formatoColunasPlanilha
                                                  "
                                                  v-bind:autocomplete="'new'"
                                                  v-bind:altlabel="
                                                    'Transporte intramunicipal'
                                                  "
                                                  v-bind:tip="
                                                    'Informe o valor aproximado gasto com transporte dentro da cidade por ' +
                                                      tipo.tip
                                                  "
                                                  v-bind:options="{
                                                    outputMask:
                                                      '################',
                                                    length: 6,
                                                    precision: 0,
                                                    locale: 'pt-BR',
                                                  }"
                                                  v-model="
                                                    local.despesas[local.dia][
                                                      tipo.key
                                                    ]
                                                      .despesatransporteRedondezas
                                                  "
                                                />
                                              </td>
                                              <td>
                                                <h-money-input
                                                  class="right-input"
                                                  v-bind:properties="
                                                    formatoColunasPlanilha
                                                  "
                                                  v-bind:autocomplete="'new'"
                                                  v-bind:altlabel="
                                                    'Despesas com hospedagem'
                                                  "
                                                  v-bind:tip="
                                                    'Informe o valor gasto com hospedagem ' +
                                                      tipo.tip
                                                  "
                                                  v-bind:options="{
                                                    outputMask:
                                                      '################',
                                                    length: 6,
                                                    precision: 0,
                                                    locale: 'pt-BR',
                                                  }"
                                                  v-model="
                                                    local.despesas[local.dia][
                                                      tipo.key
                                                    ].despesaHospedagem
                                                  "
                                                />
                                              </td>
                                              <td>
                                                <h-money-input
                                                  class="right-input"
                                                  v-bind:properties="
                                                    formatoColunasPlanilha
                                                  "
                                                  v-bind:autocomplete="'new'"
                                                  v-bind:altlabel="
                                                    'Despesas com alimento'
                                                  "
                                                  v-bind:tip="
                                                    'Informe o valor gasto alimentação por ' +
                                                      tipo.tip
                                                  "
                                                  v-bind:options="{
                                                    outputMask:
                                                      '################',
                                                    length: 6,
                                                    precision: 0,
                                                    locale: 'pt-BR',
                                                  }"
                                                  v-model="
                                                    local.despesas[local.dia][
                                                      tipo.key
                                                    ].despesaAlimentos
                                                  "
                                                />
                                              </td>
                                              <td>
                                                <h-money-input
                                                  class="right-input"
                                                  v-bind:properties="
                                                    formatoColunasPlanilha
                                                  "
                                                  v-bind:autocomplete="'new'"
                                                  v-bind:altlabel="
                                                    'Outras despesas'
                                                  "
                                                  v-bind:tip="
                                                    'Informe o valor gasto com outras despesas, como lembranças, aquisições de brindes, passeios pagos, etc; por ' +
                                                      tipo.tip
                                                  "
                                                  v-bind:options="{
                                                    outputMask:
                                                      '################',
                                                    length: 6,
                                                    precision: 0,
                                                    locale: 'pt-BR',
                                                  }"
                                                  v-model="
                                                    local.despesas[local.dia][
                                                      tipo.key
                                                    ].despesaOutros
                                                  "
                                                />
                                              </td>
                                              <td
                                                class="total totalColumn"
                                                style="font-size:16px; font-weight: bolder; padding-bottom:17px !important"
                                              >
                                                {{
                                                  totalIndividualLinha(
                                                    local.despesas[local.dia][
                                                      tipo.key
                                                    ]
                                                  ).toLocaleString("pt-BR", {
                                                    maximumFractionDigits: 0,
                                                  })
                                                }}
                                              </td>
                                              <td
                                                class="total totalColumn"
                                                style="font-size: 16px; width:90px;  font-weight: bolder;  padding-bottom:17px !important"
                                              >
                                                {{
                                                  totalGeralLinha(
                                                    local.despesas[local.dia][
                                                      tipo.key
                                                    ]
                                                  ).toLocaleString("pt-BR", {
                                                    maximumFractionDigits: 0,
                                                  })
                                                }}
                                              </td>
                                            </template>
                                          </tr>
                                          <tr style="height:50px">
                                            <td
                                              style="padding-left:10px; font-weight:bolder"
                                            >
                                              TOTAIS
                                            </td>
                                            <td
                                              align="right"
                                              style="padding-right:15px; font-size: 16px; font-weight: bolder"
                                            >
                                              {{
                                                totalGeralGrupo(
                                                  "publico",
                                                  local.despesas[local.dia]
                                                ).toLocaleString("pt-BR", {
                                                  maximumFractionDigits: 0,
                                                })
                                              }}
                                            </td>
                                            <td
                                              align="right"
                                              style="padding-right:15px; font-size: 16px; font-weight: bolder"
                                            >
                                              {{
                                                totalGeralGrupo(
                                                  "despesaTransporteChegada",
                                                  local.despesas[local.dia]
                                                ).toLocaleString("pt-BR", {
                                                  maximumFractionDigits: 0,
                                                })
                                              }}
                                            </td>
                                            <td
                                              align="right"
                                              style="padding-right:15px; font-size: 16px; font-weight: bolder"
                                            >
                                              {{
                                                totalGeralGrupo(
                                                  "despesatransporteRedondezas",
                                                  local.despesas[local.dia]
                                                ).toLocaleString("pt-BR", {
                                                  maximumFractionDigits: 0,
                                                })
                                              }}
                                            </td>
                                            <td
                                              align="right"
                                              style="padding-right:15px; font-size: 16px; font-weight: bolder"
                                            >
                                              {{
                                                totalGeralGrupo(
                                                  "despesaHospedagem",
                                                  local.despesas[local.dia]
                                                ).toLocaleString("pt-BR", {
                                                  maximumFractionDigits: 0,
                                                })
                                              }}
                                            </td>
                                            <td
                                              align="right"
                                              style="padding-right:15px; font-size: 16px; font-weight: bolder"
                                            >
                                              {{
                                                totalGeralGrupo(
                                                  "despesaAlimentos",
                                                  local.despesas[local.dia]
                                                ).toLocaleString("pt-BR", {
                                                  maximumFractionDigits: 0,
                                                })
                                              }}
                                            </td>
                                            <td
                                              align="right"
                                              style="padding-right:15px; font-size: 16px; font-weight: bolder"
                                            >
                                              {{
                                                totalGeralGrupo(
                                                  "despesaOutros",
                                                  local.despesas[local.dia]
                                                ).toLocaleString("pt-BR", {
                                                  maximumFractionDigits: 0,
                                                })
                                              }}
                                            </td>
                                            <td
                                              align="right"
                                              style="padding-right:15px;font-weight:bold"
                                            >
                                              {{
                                                totalIndividualLocalDiaPessoa(
                                                  local.despesas[local.dia]
                                                ).toLocaleString("pt-BR", {
                                                  maximumFractionDigits: 0,
                                                })
                                              }}
                                            </td>
                                            <td
                                              align="right"
                                              style="padding-right:15px;font-weight:bold"
                                            >
                                              {{
                                                totalGeralDiaLocal(
                                                  local.despesas[local.dia]
                                                ).toLocaleString("pt-BR", {
                                                  maximumFractionDigits: 0,
                                                })
                                              }}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td></td>
                                            <td colspan="6">
                                              <v-progress-linear
                                                stream
                                                buffer-value="0"
                                                v-bind:value="
                                                  percentualLotacao(
                                                    local,
                                                    local.despesas[local.dia]
                                                  )
                                                "
                                                height="40"
                                              >
                                                <template
                                                  v-slot:default="{ value }"
                                                >
                                                  <strong>{{ value }}%</strong>
                                                  {{
                                                    "..da capacidade total de "
                                                  }}
                                                  :
                                                  <strong>{{
                                                    parseInt(local.lotacao)
                                                  }}</strong>
                                                </template>
                                              </v-progress-linear>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </v-sheet>
                                  </v-tab-item>
                                </v-tabs-items>
                              </template>
                            </v-tabs>
                          </template>
                        </v-card-text>
                      </td>
                    </tr>
                  </table>
                </v-sheet>
                <v-sheet>
                  <v-btn color="primary" @click="addNovoLocal()">
                    Adicionar outro local
                    <v-icon dark>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-sheet>
              </template>
            </template>
          </v-tab-item>

          <v-tab-item>
            <v-row>
              <v-col>{{ prj.name }}</v-col>
            </v-row>
            <v-row>
              <v-col
                v-for="item in resumoIcons"
                v-bind:key="item.title"
                :cols="12"
                md="3"
              >
                <v-card flat>
                  <v-card-title class="text-subtitle-2" v-text="item.title" />
                  <div class="d-flex flex-no-wrap justify-left">
                    <v-avatar class="ma-1" size="70" tile>
                      <v-img
                        v-bind:src="'/images/' + item.icon"
                        tile
                        size="150"
                      />
                    </v-avatar>
                    <v-card-title
                      class="text-h5 red--text"
                      v-text="item.value"
                    />
                  </div>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="5" lg="5">
                <v-card>
                  <v-card-title>
                    {{ prj.nome || "Projeto " }}
                  </v-card-title>
                  <v-card-text>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Valor total</v-list-item-title>
                          <v-list-item-subtitle
                            >{{ toMoney(totalGeralProjeto()) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Publico Total</v-list-item-title>
                          <v-list-item-subtitle
                            >{{ toMoney(metricaPublicoTotal()) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >GOP - Despesas da organização do projeto
                          </v-list-item-title>
                          <v-list-item-subtitle
                            >{{ toMoney(metricaGOP()) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >Investimento per capita</v-list-item-title
                          >
                          <v-list-item-subtitle
                            >{{ toMoney(metricaInvestimentoPerCapita()) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >ID - Impacto Direto</v-list-item-title
                          >
                          <v-list-item-subtitle
                            >{{ toMoney(metricaID()) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >II - Impacto Indireto</v-list-item-title
                          >
                          <v-list-item-subtitle
                            >{{ toMoney(metricaII()) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >IT - Impacto Total</v-list-item-title
                          >
                          <v-list-item-subtitle
                            >{{ toMoney(metricaIT()) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >IAE - Índice de Alavancagem Econômica
                          </v-list-item-title>
                          <v-list-item-subtitle
                            >{{ toMoney(metricaIAE()) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h5>powered by - lá lá lá</h5>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<style lang="css" scoped>
.menuLateral {
}

.tableContainer {
}

.chart {
  height: 400px !important;
  width: 600px !important;
}

.sheetResumo {
  border: solid 1px #e0e0e0;
}

.sheetResumo td {
  padding: 3px 10px;
}

.errorCell {
  background-color: red;
  color: white;
  font-weight: bold;
}

.sheetResumo td:first-child {
  width: 300px;
}

.echarts {
  width: 400px;
  height: 200px;
}

span.sheet {
  margin-top: 50px;
}

.right-input >>> input {
  text-align: right;
}

.overflow {
  background-color: red;
  color: white;
}

.sheetColor1title {
  background-color: red;
}

.dataSheet {
  border-top: none;
  border-left: none;
  border-right: none;
  border-color: #f0f0f0;
}

.dataSheet tr td:first-child {
  border-left: none;
}

.dataSheet tr td:last-child {
  border-right: none;
}

.dataSheet thead th {
  color: #ee837a;
  text-transform: uppercase;
  font-size: 0.9em;
}

.dataSheet thead th:first-child {
  border-bottom: none;
  border-left: none;
}

.dataSheet .v-input__control {
  border: 0px 0px 1px 0px !important;
}

.totalFontesRecurso >>> input {
  font-size: 35px !important;
  color: #573688 !important;
  font-weight: bolder !important;
  border-bottom: none;
}

.totalFontesRecurso .v-input__control {
  border: none;
}

.v-application .transparent {
  border-color: #573688 !important;
  background-color: yellow !important;
}

.dataSheet tr td {
  padding-top: 20px;
}

.sheet th {
  padding: 2px 10px;
}

.sheet .total {
  padding-right: 10px;
  text-align: right;
}

.sheet .totalColumn {
  background-color: #e9e9e9;
}

.dataSheet thead th {
  background-color: #f0f0f0;
}

.dataSheet tbody tr td:first-child {
  padding-bottom: 20px;
}

.dataSheet {
  border-collapse: separate;
  border-spacing: 1px;
}

.dataSheet tbody tr td {
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: solid 1px #d0d0d0;
}

.tipoPublico {
  display: block;
  border-bottom: solid 1px #d0d0d0;
  padding-bottom: 5px;
  padding-top: 0px;
  text-transform: uppercase;
  color: #573688;
  font-weight: bolder;
}

.sheet {
  border: none;
}
</style>

<script>
import router from "../../router";

import HTextInput from "../custom/HTextInput";
import HTextArea from "../custom/HTextArea";
import HMoneyInput from "../custom/HMoneyInput.vue";
import Confirm from "../custom/HConfirm";

const clearValue = (value) => {
  if (typeof value == "string") {
    value = "0" + value.replace(/[^0-9]/g, "");
  }
  value = Math.trunc(parseFloat(value)).toFixed(0);
  return value.replace(/[^0-9]/g, "");
};

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);
export default {
  components: {
    HTextInput,
    HTextArea,
    HMoneyInput,
    Confirm,
    VChart,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  data: () => ({
    sheetState: undefined,
    sheetColors: [
      {
        color: "#573688",
        background: "#f0c647",
        title: {
          color: "#573688",
          background: "#f0c647",
        },
      },
      {
        color: "white",
        background: "#573688",
        title: {
          color: "white",
          background: "#573688",
        },
      },
      {
        color: "white",
        background: "#e53d31",
        title: {
          color: "white",
          background: "#e53d31",
        },
      },
      {
        color: "white",
        background: "blue",
        title: {
          color: "black",
          background: "#91c0ed",
        },
      },
      {
        color: "white",
        background: "#ae7918",
        title: {
          color: "white",
          background: "#c29336",
        },
      },
      {
        color: "white",
        background: "#359e0d",
        title: {
          color: "white",
          background: "#66cc66",
        },
      },
    ],
    formatoGeralInput: {
      outlined: true,
    },
    formatoColunasPlanilha: {
      outlined: false,
      dense: true,
      style: "width:130px;padding:15px 10px 0px 10px",
      // focus: "$event.target.select()"
    },
    tipoPublico: [
      {
        label: "Local",
        key: "local",
        tip: "residentes próximas ao local do evento",
      },
      {
        label: "Excursionista",
        key: "excursionista",
        tip: "excursionistas (sem pernoite)",
      },
      {
        label: "Nacional",
        key: "nacional",
        tip:
          "brasileiros que passarão a temporada na região em função do evento",
      },
      {
        label: "Internacional",
        key: "internacional",
        tip:
          "estrangeiros que passarão a temporada na região em função do evento",
      },
    ],
    segmentos: [
      {
        id: 100,
        label: "Artes Cênicas",
        f: 1.6,
      },
      {
        id: 110,
        label: "Artes visuais",
        f: 1.58,
      },
      {
        id: 120,
        label: "Audiovisual",
        f: 1.61,
      },
      {
        id: 130,
        label: "Humanidades (setor editorial)",
        f: 1.69,
      },
      {
        id: 140,
        label: "Patrimônio Cultural",
        f: 1.51,
      },
      {
        id: 150,
        label: "Música",
        f: 1.64,
      },
      {
        id: 160,
        label: "Cultura Popular",
        f: 1.51,
      },
    ],
    dia: 0,
    prj: {},
    fontesRecurso: [
      {
        code: "FRPUB",
        label: "Recursos Públicos R$",
        hint:
          "Valor total originado de editais, leis de incentivo e fundos públicos",
      },
      {
        code: "FRPRV",
        label: "Recursos Privados R$",
        hint:
          "Valor total de patrocínio não incentivado, tais como doações e apoios financeiros de empresas privadas",
      },
      {
        code: "FRRP",
        label: "Recursos Próprios R$",
      },
    ],
    emptyPrj: {
      titulo: "",
      proponente: "",
      resumo: "",
      segmento: null,
      valorDireitosAutorais: 0,
      acervoImovel: "",
      locais: [
        {
          dia: 0,
          estabelecimento: null,
          estado: "",
          cidade: "",
          lotacao: 0,
          qtApresentacoes: 1,
          despesas: [
            {
              local: {
                publico: 0,
                despesaTransporteChegada: 0,
                despesatransporteRedondezas: 0,
                despesaHospedagem: 0,
                despesaAlimentos: 0,
                despesaOutros: 0,
              },
              excursionista: {
                publico: 0,
                despesaTransporteChegada: 0,
                despesatransporteRedondezas: 0,
                despesaHospedagem: 0,
                despesaAlimentos: 0,
                despesaOutros: 0,
              },
              nacional: {
                publico: 0,
                despesaTransporteChegada: 0,
                despesatransporteRedondezas: 0,
                despesaHospedagem: 0,
                despesaAlimentos: 0,
                despesaOutros: 0,
              },
              internacional: {
                publico: 0,
                despesaTransporteChegada: 0,
                despesatransporteRedondezas: 0,
                despesaHospedagem: 0,
                despesaAlimentos: 0,
                despesaOutros: 0,
              },
            },
          ],
        },
      ],
      fontesRecurso: [
        {
          code: "FRPUB",
          value: "",
          label: "Públicos R$",
          hint:
            "Valor total originado de editais, leis de incentivo e fundos públicos",
        },
        {
          code: "FRPRV",
          value: "",
          label: "Privados R$",
          hint:
            "Valor total de patrocínio não incentivado, tais como doações e apoios financeiros de empresas privadas",
        },
        {
          code: "FRRP",
          value: "",
          label: "Próprios R$",
          hint:
            "Total de valores previstos por arrecadação com ingressos, vendas de produtos e capital próprio",
        },
      ],
    },
    tabopt: null,
    isActiveFontesRecurso: false,
    isInitializedFontesRecurso: false,
  }),
  computed: {
    resumoIcons() {
      return [
        {
          icon: "icon-valor-realizacao.png",
          title: "Despesas de Realização do Projeto",
          value: "R$ " + this.toMoney(this.totalGeralProjeto()),
        },
        {
          icon: "icon-publico-total.png",
          title: "Público Total",
          value: this.toMoney(this.metricaPublicoTotal()) + " pessoas",
        },
        {
          icon: "icon-investimento-per-capita.png",
          title: "Investimento per capita",
          value: "R$ " + this.toMoney(this.metricaInvestimentoPerCapita()),
        },
        {
          icon: "icon-ied.png",
          title: "Impacto econômico direto",
          value: "R$ " + this.toMoney(this.metricaID()),
        },
        {
          icon: "icon-ied.png",
          title: "Impacto econômico indireto",
          value: "R$ " + this.toMoney(this.metricaII()),
        },
        {
          icon: "icon-ietotal.png",
          title: "Impacto econômico total",
          value: "R$ " + this.toMoney(this.metricaIT()),
        },
        {
          icon: "icon-iae.png",
          title: "Índice de Avalancagem Econômica",
          value: this.toMoney(this.metricaIAE()),
        },
      ];
    },
    resumoLocais() {
      return (
        (this.prj.locais &&
          this.prj.locais.map((local) => {
            var publicoLocal = this.totalPublicoLocal(local);
            var individualLocal = this.totalIndividualLocal(local);
            return {
              estabelecimento: local.estabelecimento,
              totalPublicoLocal: publicoLocal,
              totalIndividualLocal: individualLocal,
              totalGeralLocal: publicoLocal * individualLocal,
            };
          })) ||
        []
      );
    },
    valorTotalFonteRecurso() {
      // return 1000
      return (
        (this.prj.fontesRecurso &&
          this.prj.fontesRecurso.reduce(
            (acc, item) => acc + (clearValue(item.value) - 0),
            0
          )) ||
        0
      );
    },
    dadosSerieFonteRecurso() {
      return (
        (this.prj.fontesRecurso &&
          this.prj.fontesRecurso.map((e) => {
            // let perc = Math.round(Math.trunc((e.value / this.valorTotalFonteRecurso) * 10000) / 100, 2)
            // let perc = 40
            // let compl = isNaN(perc) ? '' : `\n(${  perc }%)`
            var label = this.fontesRecurso.find((o) => o.code == e.code).label;
            return {
              name: label, // + compl,
              value: clearValue(e.value) - 0,
            };
          })) ||
        []
      );
    },
    dadosGraficoFonteRecurso() {
      return {
        legend: {
          orient: "vertical",
          left: "right",
          top: "bottom",
          data: this.fontesRecurso.map((e) => e.label),
        },
        title: {
          show: false,
        },
        tooltip: {
          trigger: "item",
          formatter: "<b>{b}</b>{c}<br/>({d}%)",
        },
        series: {
          type: "pie",
          data: this.dadosSerieFonteRecurso,
          center: ["60%", "40%"],
          radius: ["25%", "60%"],
          itemStyle: {
            borderRadius: 7,
            borderWidth: 2,
          },
          label: {
            show: true,
          },
          color: ["#f0c647", "#573688", "#e53d31"],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      };
    },
  },
  methods: {
    adicionarDias(local) {
      // console.log("****** adicionar dias clicado", local )
      local.qtApresentacoes++;
      this.$nextTick(function() {
        local.dia = local.qtApresentacoes - 1;
      });
    },
    removerDias(local) {
      // console.log("****** remover dias clicado", local )
      if (local.dia < local.qtApresentacoes - 1) local.dia--;
      local.qtApresentacoes--;
    },
    removeLocal(idx) {
      this.$refs.confirm
        .open("Excluir local", "Tem certeza?", {
          color: "red",
        })
        .then((confirmado) => {
          if (confirmado) {
            this.prj.locais.splice(idx, 1);
          }
        });
    },
    async loadProject() {
      var projectId = router.currentRoute.params.id;
      if (!projectId || projectId == "novo") {
        this.prj = {
          ...this.emptyPrj,
        };
      } else {
        var _vm = this;
        await this.$api()
          .post("project/list", {
            _id: projectId,
          })
          .then((results) => {
            console.log(results);
            _vm.prj = results.data[0];
          })
          .catch((err) => {
            this.jsonData = err;
          });
      }
    },
    saveProject() {
      this.$api()
        .post("project/save", this.prj)
        .then((results) => {
          this.jsonData = results;
          if (results.insertedId) {
            this.prj._id = results.insertedId;
          }
        })
        .catch((err) => {
          this.jsonData = err;
        });
    },
    exitFromProject() {
      this.$refs.confirm
        .open("Saindo sem salvar", "Tem certeza?", {
          color: "red",
        })
        .then((confirm) => {
          if (confirm)
            router.push({
              name: "Projetos",
            });
        });
    },
    toMoney(val) {
      return val.toLocaleString("pt-BR", {
        maximumFractionDigits: 0,
      });
    },
    percentualLotacao(local, localDia) {
      if (local.lotacao == 0) return 0;
      var perc = Math.trunc(
        (this.totalGeralGrupo("publico", localDia) / local.lotacao) * 100
      );
      return perc;
    },
    addNovoLocal() {
      this.prj.locais.push({
        dia: 0,
        estabelecimento: "",
        estado: "",
        cidade: "",
        lotacao: 0,
        qtApresentacoes: 1,
        despesas: [
          {
            local: {
              publico: 0,
              despesaTransporteChegada: 0,
              despesatransporteRedondezas: 0,
              despesaHospedagem: 0,
              despesaAlimentos: 0,
              despesaOutros: 0,
            },
            excursionista: {
              publico: 0,
              despesaTransporteChegada: 0,
              despesatransporteRedondezas: 0,
              despesaHospedagem: 0,
              despesaAlimentos: 0,
              despesaOutros: 0,
            },
            nacional: {
              publico: 0,
              despesaTransporteChegada: 0,
              despesatransporteRedondezas: 0,
              despesaHospedagem: 0,
              despesaAlimentos: 0,
              despesaOutros: 0,
            },
            internacional: {
              publico: 0,
              despesaTransporteChegada: 0,
              despesatransporteRedondezas: 0,
              despesaHospedagem: 0,
              despesaAlimentos: 0,
              despesaOutros: 0,
            },
          },
        ],
      });
    },
    totalGeralGrupo: function(atributo, sheet) {
      // console.log( 'obtendo total geral grupo de ', atributo, sheet )
      if (!sheet) return 0;
      return Object.keys(sheet)
        .map((grupo) => (sheet[grupo] && parseInt(sheet[grupo][atributo])) || 0)
        .filter((val) => !isNaN(val))
        .reduce((acc, el) => acc + (typeof el !== "undefined" ? el - 0 : 0), 0);
    },
    parseValue: function(value) {
      if (typeof value == "undefined") return 0;
      return value - 0;
    },
    totalIndividualLocalDiaPessoa: function(plan) {
      return this.tipoPublico
        .map((tipo) => {
          return this.totalIndividualLinha(
            (plan && plan[tipo.key] && plan[tipo.key]) || null
          );
        })
        .reduce((acc, valor) => (acc += isNaN(valor) ? 0 : valor), 0);
    },
    totalIndividualLocal(local) {
      return (
        (local.despesas &&
          local.despesas
            .map((plan) => {
              return this.totalIndividualLocalDiaPessoa(plan);
            })
            .reduce((acc, valor) => (acc += isNaN(valor) ? 0 : valor), 0)) ||
        0
      );
    },
    totalGeralDiaLocal: function(plan) {
      return this.tipoPublico
        .map((tipo) => {
          return this.totalGeralLinha(
            plan && plan[tipo.key] && plan[tipo.key || null]
          );
        })
        .reduce((acc, valor) => (acc += isNaN(valor) ? 0 : valor), 0);
    },
    metricaPublicoTotal() {
      return this.totalGeralPublicoProjeto();
    },
    metricaGOP() {
      return this.valorTotalFonteRecurso;
    },
    metricaInvestimentoPerCapita() {
      return this.metricaPublicoTotal() > 0
        ? this.metricaGOP() / this.metricaPublicoTotal()
        : 0;
    },
    metricaID() {
      return (
        this.metricaGOP() *
        parseFloat((this.prj.segmento && this.prj.segmento.f) || 0)
      );
    },
    metricaII() {
      return this.totalGeralProjeto();
    },
    metricaIT() {
      return this.metricaID() + this.metricaII();
    },
    metricaIAE() {
      return this.metricaGOP() > 0 ? this.metricaIT() / this.metricaGOP() : 0;
    },
    totalGeralProjeto: function() {
      return (
        (this.prj.locais &&
          this.prj.locais
            .map((local) => {
              return local.despesas
                .map((plan) => {
                  return this.totalGeralDiaLocal(plan);
                })
                .reduce((acc, valor) => (acc += isNaN(valor) ? 0 : valor), 0);
            })
            .reduce((acc, valor) => (acc += isNaN(valor) ? 0 : valor), 0)) ||
        0
      );
    },

    totalGeralPublicoProjeto: function() {
      return (
        (this.prj.locais &&
          this.prj.locais
            .map((local) => {
              return this.totalPublicoLocal(local);
            })
            .reduce((acc, valor) => (acc += isNaN(valor) ? 0 : valor), 0)) ||
        0
      );
    },
    totalPublicoLocal: function(local) {
      if (!local) return 0;
      return local.despesas
        .map((plan) => {
          return this.totalGeralGrupo("publico", plan);
        })
        .reduce((acc, valor) => (acc += isNaN(valor) ? 0 : valor), 0);
    },
    totalGeralLinha: function(linha) {
      if (!linha) return 0;
      return this.parseValue(linha.publico) * this.totalIndividualLinha(linha);
    },
    totalIndividualLinha: function(linha) {
      // console.log( 'obtendo totalINdividual linha de ', linha )
      if (!linha) return 0;
      return (
        this.parseValue(linha.despesaTransporteChegada) +
        this.parseValue(linha.despesatransporteRedondezas) +
        this.parseValue(linha.despesaHospedagem) +
        this.parseValue(linha.despesaAlimentos) +
        this.parseValue(linha.despesaOutros)
      );
    },
    adjustSkel: function() {
      // console.log('entering adjustSkel')
      if (!this.prj.locais) {
        // console.log('ALERT: no prj.locais yet')
        return;
      }

      this.prj.locais.map((l) => {
        // console.log('checking ', l)
        if (!l.despesas || l.qtApresentacoes !== l.despesas.length) {
          if (!l.despesas) {
            // console.log('!despesas')
            l.despesas = [
              {
                local: {
                  publico: 0,
                  despesaTransporteChegada: 0,
                  despesatransporteRedondezas: 0,
                  despesaHospedagem: 0,
                  despesaAlimentos: 0,
                  despesaOutros: 0,
                },
                excursionista: {
                  publico: 0,
                  despesaTransporteChegada: 0,
                  despesatransporteRedondezas: 0,
                  despesaHospedagem: 0,
                  despesaAlimentos: 0,
                  despesaOutros: 0,
                },
                nacional: {
                  publico: 0,
                  despesaTransporteChegada: 0,
                  despesatransporteRedondezas: 0,
                  despesaHospedagem: 0,
                  despesaAlimentos: 0,
                  despesaOutros: 0,
                },
                internacional: {
                  publico: 0,
                  despesaTransporteChegada: 0,
                  despesatransporteRedondezas: 0,
                  despesaHospedagem: 0,
                  despesaAlimentos: 0,
                  despesaOutros: 0,
                },
              },
            ];
          }
          if (l.despesas.length > l.qtApresentacoes) {
            // reduzir tamanho
            // console.log('reducing')
            while (l.despesas.length > Math.max(l.qtApresentacoes, 1)) {
              // console.log('deduzindo um de desp # atual ', l.despesas.length)
              l.despesas.pop();
            }
          } else {
            // aumentar com esqueleto
            // console.log('skelekton filler')
            var qtMore = l.qtApresentacoes - l.despesas.length;
            for (var i = 0; i < qtMore; i++) {
              // console.log(i)
              l.despesas.push({
                local: {
                  publico: 0,
                  despesaTransporteChegada: 0,
                  despesatransporteRedondezas: 0,
                  despesaHospedagem: 0,
                  despesaAlimentos: 0,
                  despesaOutros: 0,
                },
                excursionista: {
                  publico: 0,
                  despesaTransporteChegada: 0,
                  despesatransporteRedondezas: 0,
                  despesaHospedagem: 0,
                  despesaAlimentos: 0,
                  despesaOutros: 0,
                },
                nacional: {
                  publico: 0,
                  despesaTransporteChegada: 0,
                  despesatransporteRedondezas: 0,
                  despesaHospedagem: 0,
                  despesaAlimentos: 0,
                  despesaOutros: 0,
                },
                internacional: {
                  publico: 0,
                  despesaTransporteChegada: 0,
                  despesatransporteRedondezas: 0,
                  despesaHospedagem: 0,
                  despesaAlimentos: 0,
                  despesaOutros: 0,
                },
              });
            }
          }
        }
      });
      // console.log('end adjustskel')
    },
  },
  watch: {
    "prj.locais": {
      deep: true,
      immediate: true,
      handler: function(n) {
        if (!n) return;

        var changed = false;

        if (typeof this.sheetState == "undefined") {
          changed = true;
        } else if (Object.keys(this.sheetState).length !== n.length) {
          // console.log('detected length changed ', Object.keys(this.sheetState).length || ' new', n
          // .length)
          changed = true;
        } else {
          if (
            JSON.stringify(this.sheetState) !==
            JSON.stringify(n.map((p) => p.qtApresentacoes))
          )
            changed = true;
        }

        if (changed) {
          // console.log('***** changed')
          this.adjustSkel();
          this.sheetState = n && n.map((p) => p.qtApresentacoes);
          // console.log('end changed if')
        }
      },
    },
  },
  async beforeRouteUpdate(to, from, next) {
    // console.log('before route update ')
    await this.loadProject();
    next();
  },
  async beforeMount() {
    // console.log('before mount')
    await this.loadProject();
  },
};
</script>
